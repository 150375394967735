
  import { Component, Vue } from "vue-property-decorator";
  import {
    readEnrollments,
    readDirectoryEntry,
    readToken,
    readCertificate,
    readRecertEnrolled,
  } from "@/store/main/getters";
  import {
    dispatchGetEnrollments,
    dispatchGetDirectoryEntry,
    dispatchCheckApiError,
    dispatchGetCertificate,
    dispatchGetRecertEnrolled,
  } from "@/store/main/actions";
  import DirectoryEntry from "@/components/DirectoryEntry.vue";
  import CourseCard from "@/components/CourseCard.vue";
  import { IMaterialsDownload } from "@/interfaces";
  import { apiUrl, THINKIFIC_POST_TEST_GRACE_PERIOD_DAYS } from "@/env";
  import { api } from "@/api";

  @Component({
    components: {
      DirectoryEntry,
      CourseCard,
    },
  })
  export default class Dashboard extends Vue {
    dialog = false;
    checkoutSuccess = false;
    materialsDownload: IMaterialsDownload | null = null;
    canPurchaseMaterialDownloads = false;
    loading = false;

    get enrollments() {
      const enrollments = readEnrollments(this.$store);
      return enrollments && enrollments.length > 0 ? [enrollments[0]] : [];
    }

    get enrolledInUpcomingEvent() {
      if (this.enrollments && this.enrollments.length > 0) {
        const expir = new Date(this.enrollments[0].event.start_date);
        // Don't consider them unenrolled until the window to take the post test is over
        expir.setDate(expir.getDate() + THINKIFIC_POST_TEST_GRACE_PERIOD_DAYS);
        return expir.getTime() > new Date().getTime();
      }
      return false;
    }

    get directoryEntry() {
      return readDirectoryEntry(this.$store);
    }

    get certificate() {
      return readCertificate(this.$store);
    }

    get certified() {
      if (this.certificate?.expiration_date) {
        const expDate = new Date(this.certificate.expiration_date);
        const now = new Date();
        return now.getTime() < expDate.getTime();
      }
      return false;
    }

    get certificationExpirationDate() {
      if (this.certificate?.expiration_date) {
        const expDate = new Date(this.certificate.expiration_date);
        return new Intl.DateTimeFormat([], {
          dateStyle: "full",
        }).format(expDate);
      }
      return null;
    }

    get certificationExpired() {
      if (this.certificate && this.certificate.expiration_date) {
        return this.daysUntilExpiration > 0 ? false : true;
      }
      return false;
    }

    get daysUntilExpiration() {
      if (this.certificate?.expiration_date) {
        const today = new Date();
        const expDate = new Date(this.certificate.expiration_date);

        const diff = expDate.getTime() - today.getTime(); // gets difference in time, measured in ms
        const diffDays = diff / 1000 / 60 / 60 / 24;

        return diffDays > 0 ? diffDays : 0;
      }
      return 0;
    }

    get enrolledInRecertCourse() {
      return readRecertEnrolled(this.$store);
    }

    get certificateAboutToExpire() {
      if (this.certificate?.expiration_date) {
        const dateInRange = this.daysUntilExpiration < 180 ? true : false;
        return dateInRange;
      }
      return false;
    }

    downloadMaterials() {
      this.dialog = false;
      setTimeout(this.getMaterialsDownload, 3000);
      window.open(`${apiUrl}/api/v1/material_downloads/download/`, "_blank");
    }

    async getMaterialsDownload() {
      const token = readToken(this.$store);
      try {
        const resp = await api.getMaterialsDownload(token, null);
        const materialsDownload = resp.data;
        const effectiveDate =
          materialsDownload && materialsDownload.effective_date
            ? new Date(materialsDownload.effective_date)
            : null;
        const expirationDate =
          materialsDownload && materialsDownload.expiration_date
            ? new Date(materialsDownload.expiration_date)
            : null;
        const now = new Date();
        if (
          (!effectiveDate || now.getTime() >= effectiveDate.getTime()) &&
          (!expirationDate || now.getTime() < expirationDate.getTime())
        ) {
          this.materialsDownload = materialsDownload;
        } else {
          this.materialsDownload = null;
        }
      } catch (error) {
        this.materialsDownload = null;
        dispatchCheckApiError(this.$store, error);
      }
    }

    async checkCanPurchaseMaterialsDownload() {
      const token = readToken(this.$store);
      try {
        const resp = await api.checkCanPurchaseMaterialsDownload(token);
        this.canPurchaseMaterialDownloads = resp.data.can_purchase;
      } catch (error) {
        this.canPurchaseMaterialDownloads = false;
        dispatchCheckApiError(this.$store, error);
      }
    }

    async goToThinkific(toCertificates = false, toRecert = false) {
      const token = readToken(this.$store);
      try {
        this.loading = true;
        const resp = await api.getThinkificSSOUrl(token, toCertificates, toRecert);
        window.location.href = resp.data.url;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        dispatchCheckApiError(this.$store, error);
      }
    }

    async logoutThinkificGoToLogin() {
      // This method opens the thinkific sign out page in a separate tab,
      // closes it immediately, then opens the thinkific login page. This is done because
      // theres no way to control where the thinkific logout redirects to after logout,
      // and when it goes to the thinkific school homepage the user cant get to the thinkific
      // sign in (the sign in link there goes to fdi2.com login).
      // This solution was taken from https://stackoverflow.com/a/52462427/9686950
      try {
        const wnd = window.open("https://degluted.fdi2.com/users/sign_out", "_blank");

        const defer = function (callback) {
          var channel = new MessageChannel();
          channel.port1.onmessage = function () {
            callback();
          };
          channel.port2.postMessage(null);
        };

        const callback = function () {
          defer(function () {
            if (wnd) {
              wnd.close();
            }
            window.location.href = "https://degluted.fdi2.com/users/sign_in";
          });
        };

        if (wnd) {
          wnd.addEventListener("unload", callback, true);
          wnd.addEventListener("pagehide", callback, true);
        }
      } catch (error) {
        dispatchCheckApiError(this.$store, error);
      }
    }

    public async mounted() {
      this.checkoutSuccess =
        (this.$router.currentRoute.query.checkout_success as string) === "true";
      this.getMaterialsDownload();
      setTimeout(this.getMaterialsDownload, 5000);
      this.checkCanPurchaseMaterialsDownload();

      await Promise.all([
        await dispatchGetEnrollments(this.$store),
        await dispatchGetDirectoryEntry(this.$store),
        await dispatchGetCertificate(this.$store),
        await dispatchGetRecertEnrolled(this.$store),
      ]);
    }
  }
